<template>
  <ContentWrapper :links="topLinks">
    <Alert type="error">
      这个页面涉及到应用具体的一些行为表现，请在确定弄清楚自己在改什么之后再进行配置
    </Alert>
    <ButtonGroup size="large">
      <Button type="primary" @click="submitHandler" :disabled="loading">保存所有配置</Button>
      <Button @click="resetHandler">重置为当前线上配置</Button>
    </ButtonGroup>
    <Form :model="settings" :labelWidth="240">
      <Divider>iOS 自动升级相关设置</Divider>
      <FormItem :label="desc['ios_latest_version']">
        <Input :placeholder="desc['ios_latest_version']" v-model="settings['ios_latest_version']" />
        <span class="app-settings-key">key: ios_latest_version</span>
      </FormItem>
      <FormItem :label="desc['ios_latest_build']">
        <Input :placeholder="desc['ios_latest_build']" v-model="settings['ios_latest_build']" />
        <span class="app-settings-key">key: ios_latest_build</span>
      </FormItem>
      <FormItem :label="desc['ios_force_build']">
        <Input :placeholder="desc['ios_force_build']" v-model="settings['ios_force_build']" />
        <span class="app-settings-key">key: ios_force_build</span>
      </FormItem>
      <FormItem :label="desc['ios_changelog']">
        <Input :placeholder="desc['ios_changelog']" type="textarea" :rows="4" v-model="settings.ios_changelog" />
        <span class="app-settings-key">key: ios_changelog</span>
      </FormItem>
      <FormItem :label="desc['ios_force_log']">
        <Input :placeholder="desc['ios_force_log']" type="textarea" :rows="4" v-model="settings.ios_force_log" />
        <span class="app-settings-key">key: ios_force_log</span>
      </FormItem>
      <FormItem :label="desc['ios_link']">
        <Input :placeholder="desc['ios_link']" v-model="settings['ios_link']" />
        <span class="app-settings-key">key: ios_link</span>
      </FormItem>
      <Divider>Android 自动升级相关设置 - Google Play 渠道</Divider>
      <FormItem :label="desc['android_play_latest_version']">
        <Input :placeholder="desc['android_play_latest_version']" v-model="settings['android_play_latest_version']" />
        <span class="app-settings-key">key: android_play_latest_version</span>
      </FormItem>
      <FormItem :label="desc['android_play_latest_build']">
        <Input :placeholder="desc['android_play_latest_build']" v-model="settings['android_play_latest_build']" />
        <span class="app-settings-key">key: android_play_latest_build</span>
      </FormItem>
      <FormItem :label="desc['android_play_force_build']">
        <Input :placeholder="desc['android_play_force_build']" v-model="settings['android_play_force_build']" />
        <span class="app-settings-key">key: android_play_force_build</span>
      </FormItem>
      <FormItem :label="desc['android_play_changelog']">
        <Input
          :placeholder="desc['android_play_changelog']"
          type="textarea"
          :rows="4"
          v-model="settings['android_play_changelog']"
        />
        <span class="app-settings-key">key: android_play_changelog</span>
      </FormItem>
      <FormItem :label="desc['android_play_force_log']">
        <Input
          :placeholder="desc['android_play_force_log']"
          type="textarea"
          :rows="4"
          v-model="settings['android_play_force_log']"
        />
        <span class="app-settings-key">key: android_play_force_log</span>
      </FormItem>
      <FormItem :label="desc['android_play_link']">
        <Input :placeholder="desc['android_play_link']" v-model="settings['android_play_link']" />
        <span class="app-settings-key">key: android_play_link</span>
      </FormItem>
      <Divider>Android 自动升级相关设置 - 华为渠道</Divider>
      <FormItem :label="desc['android_huawei_latest_version']">
        <Input
          :placeholder="desc['android_huawei_latest_version']"
          v-model="settings['android_huawei_latest_version']"
        />
        <span class="app-settings-key">key: android_huawei_latest_version</span>
      </FormItem>
      <FormItem :label="desc['android_huawei_latest_build']">
        <Input :placeholder="desc['android_huawei_latest_build']" v-model="settings['android_huawei_latest_build']" />
        <span class="app-settings-key">key: android_huawei_latest_build</span>
      </FormItem>
      <FormItem :label="desc['android_huawei_force_build']">
        <Input :placeholder="desc['android_huawei_force_build']" v-model="settings['android_huawei_force_build']" />
        <span class="app-settings-key">key: android_huawei_force_build</span>
      </FormItem>
      <FormItem :label="desc['android_huawei_changelog']">
        <Input
          :placeholder="desc['android_huawei_changelog']"
          type="textarea"
          :rows="4"
          v-model="settings['android_huawei_changelog']"
        />
        <span class="app-settings-key">key: android_huawei_changelog</span>
      </FormItem>
      <FormItem :label="desc['android_huawei_force_log']">
        <Input
          :placeholder="desc['android_huawei_force_log']"
          type="textarea"
          :rows="4"
          v-model="settings['android_huawei_force_log']"
        />
        <span class="app-settings-key">key: android_huawei_force_log</span>
      </FormItem>
      <FormItem :label="desc['android_huawei_link']">
        <Input :placeholder="desc['android_huawei_link']" v-model="settings['android_huawei_link']" />
        <span class="app-settings-key">key: android_huawei_link</span>
      </FormItem>
      <Divider>Android 自动升级相关设置 - 官网渠道</Divider>
      <FormItem :label="desc['android_latest_version']">
        <Input :placeholder="desc['android_latest_version']" v-model="settings['android_latest_version']" />
        <span class="app-settings-key">key: android_latest_version</span>
      </FormItem>
      <FormItem :label="desc['android_latest_build']">
        <Input :placeholder="desc['android_latest_build']" v-model="settings['android_latest_build']" />
        <span class="app-settings-key">key: android_latest_build</span>
      </FormItem>
      <FormItem :label="desc['android_force_build']">
        <Input :placeholder="desc['android_force_build']" v-model="settings['android_force_build']" />
        <span class="app-settings-key">key: android_force_build</span>
      </FormItem>
      <FormItem :label="desc['android_changelog']">
        <Input
          :placeholder="desc['android_changelog']"
          type="textarea"
          :rows="4"
          v-model="settings['android_changelog']"
        />
        <span class="app-settings-key">key: android_changelog</span>
      </FormItem>
      <FormItem :label="desc['android_force_log']">
        <Input
          :placeholder="desc['android_force_log']"
          type="textarea"
          :rows="4"
          v-model="settings['android_force_log']"
        />
        <span class="app-settings-key">key: android_force_log</span>
      </FormItem>
      <FormItem :label="desc['android_link']">
        <Input :placeholder="desc['android_link']" v-model="settings['android_link']" />
        <span class="app-settings-key">key: android_link</span>
      </FormItem>
    </Form>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import { queryAllSettings, updateAllSettings } from "@/api"
import { apiUtil } from "@/util"

const settingKeys = [
  "android_changelog",
  "android_force_log",
  "android_force_build",
  "android_latest_build",
  "android_latest_version",
  "android_link",
  "android_huawei_changelog",
  "android_huawei_force_log",
  "android_huawei_force_build",
  "android_huawei_latest_build",
  "android_huawei_latest_version",
  "android_huawei_link",
  "android_play_changelog",
  "android_play_force_log",
  "android_play_force_build",
  "android_play_latest_build",
  "android_play_latest_version",
  "android_play_link",
  "ios_changelog",
  "ios_force_log",
  "ios_force_build",
  "ios_latest_build",
  "ios_latest_version",
  "ios_link",
]

const initObjects = () => {
  const obj = {}
  settingKeys.forEach(k => {
    obj[k] = "加载中"
  })
  return obj
}

export default {
  name: "AppSettingsPage",
  components: { ContentWrapper },
  data() {
    return {
      topLinks: [{ title: "应用相关配置", link: this.$route.path }],
      desc: Object.assign({}, initObjects()),
      settings: Object.assign({}, initObjects()),
      loading: true,
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      apiUtil.call(this, async () => {
        this.loading = true
        const fetchedSettings = await queryAllSettings()
        Object.keys(fetchedSettings).forEach(k => {
          this.desc[k] = fetchedSettings[k][0]
          this.settings[k] = fetchedSettings[k][1]
        })
        this.loading = false
      })
    },
    async submitHandler() {
      apiUtil.call(this, async () => {
        this.loading = true
        await updateAllSettings(Object.assign({}, this.settings))
        await this.reload()
      })
    },
    resetHandler() {
      this.settings = Object.assign({}, this.settings, initObjects())
      this.reload()
    },
  },
}
</script>

<style scope>
.app-settings-key {
  color: #808695;
}
p.preview {
  width: 200px;
  height: 100px;
  display: inline-block;
  margin-right: 10px;
}
p.preview > span {
  display: block;
  width: 120px;
  height: 40px;
  margin: 30px auto 0;
  color: #fff;
  text-align: center;
  line-height: 40px;
}
p.white {
  background: #fff;
}
p.black {
  background: #333;
}
</style>
